import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import ToolsBanner from "components/commontools/toolsBanner";
import Layout from "components/layout";

import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { ContextualToolFaqData } from "components/FAQ/config";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import axios from "axios";
import SourceLanguage from "components/ToolsLanguageDropdown/SourceLangauge";
import TargetLanguage from "components/ToolsLanguageDropdown/TargetLanguage";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [wordCountInput, setWordCountInput] = useState(0);
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const containerRef = useRef();
  const [urlText, setUrlText] = useState("");

  const [isEnterText, setIsEnterText] = useState(true);

  const debouncedInputText = useDebounce(inputText);
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      const languageName = Languages.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }
    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    let payload;
    if (urlText) {
      payload = {
        tool_name: "contextual_glossary_generator",
        user_url: urlText,
      };
    } else {
      payload = {
        tool_name: "contextual_glossary_generator",
        user_text: inputText,
        source_language_code: inputLang,
      };
    }

    if (outputLang) {
      payload.target_language_code = outputLang;
    }

    try {
      const response = await http().post(endpoints.forms.aiTools, payload);
      const newShareId = response?.meta?.share_id;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(
        `/tools/contextual-glossary-generator/result?share-id=${newShareId}`
      );
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !urlText && !(inputText || inputLang);

  const handleClearText = () => {
    setInputText("");
    setWordCountInput(0);
    setError("");
  };

  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
    // setHasSummary(false);
  };
  // const toggleInput = (isTextInput) => {
  //   setIsEnterText(isTextInput);
  //   setUrlText("");
  //   setInputText("");
  // };
  const clearLink = () => {
    setUrlText("");
  };
  return (
    <Layout>
      <SEO
        title="Key Terms Glossary with Translation"
        description="Create industry-specific glossaries effortlessly with the Key Terms Glossary tool. Analyze text in any language to generate key terms with English definitions and translations in your chosen language."
        slug="/tools/contextual-glossary-generator"
      />

      <div className="bg-[#F5F5F5]">
        <div className="w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Key Terms Glossary with Translation"
            title="Identify, Define, and Translate Key Terms from Any Text Instantly"
            description="The Key Terms Glossary quickly identifies specialized and industry-specific terms from text in any language. It provides you with a short, contextual definition in English. If a target language is selected, a translation is shown for each key term."
          />

          <div className="lg:flex gap-3 h-full mt-12">
            <div
              ref={containerRef}
              className={`flex flex-col gap-3 w-full lg:w-[65%] bg-white h-full rounded-lg shadow-lg`}
            >
              {isEnterText && (
                <div className="flex md:flex-row flex-col px-3 mt-3 gap-3">
                  <SourceLanguage
                    inputLang={inputLang}
                    setInputLang={setInputLang}
                    containerRef={containerRef}
                  />
                  <TargetLanguage
                    outputLang={outputLang}
                    setOutputLang={setOutputLang}
                    containerRef={containerRef}
                  />
                </div>
              )}
              <div className="lg:flex items-center">
                <ToolInput
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setWordCount={setWordCountInput}
                  handleClearText={handleClearText}
                  isContextual={true}
                  lang={inputLang}
                  placeholder="Type your text here or"
                  tooltype="pre"
                  urlText={urlText}
                  setUrlText={setUrlText}
                  clearLink={clearLink}
                  handleLinkChange={handleLinkChange}
                  loading={loading}
                  isEnterText={isEnterText}
                  isContextualGlossary={true}
                  setIsEnterText = {setIsEnterText}
       
                >
                
                  {inputText && (
                    <img
                      src={CleanButton}
                      alt="clean-button"
                      onClick={handleClearText}
                      className={`cursor-pointer ${
                        inputLang === "fa" ||
                        inputLang === "he" ||
                        inputLang === "ar"
                          ? "left-[32px]"
                          : "right-[32px]"
                      }  absolute  top-4`}
                    />
                  )}
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              loading={loading}
              error={error}
              isContextual={true}
              buttontext="Generate Glossary"
              text={`Your results will appear here. <br /> Please enter your  ${
                isEnterText ? "texts" : "link"
              } and click 'Generate Glossary' to begin.`}
            />
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>

          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            The Key Terms Glossary quickly identifies specialized and
            industry-specific terms from text in any language. It provides you
            with a short, contextual definition in English. If a target language
            is selected, a translation is shown for each key term.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={ContextualToolFaqData} />
    </Layout>
  );
};

export default Index;
